<template>
  <div class="container">
    <nav-box title="自助开牌" :back="false"></nav-box>
    <div class="content flex-column flex-center">
      <div class="sex flex flex-center">
        <img src="@/assets/man.png" v-if="sex == 1" alt="" />
        <img src="@/assets/woman.png" v-else alt="" />
        <span>{{ sex == 1 ? "男宾" : "女宾" }}</span>
      </div>
      <div class="code-box flex-column flex-center">
        <div class="code">
          <img :src="imageUrl" alt="" />
        </div>
        <p class="tip">请使用“ 微信 ”扫码获取宾客匙牌</p>
      </div>
    </div>
    <div class="open flex flex-center" @click="openCard">
      <span>人工开牌</span>
    </div>
    <!-- 显示扫码 -->
    <div class="pay-popup flex-column flex-center" v-if="showLogin">
      <div class="pay-window flex-column">
        <div class="top-tab flex flex-center">
          <div class="tab flex" v-if="!isLogin">
            <div
              class="tab-item flex flex-center"
              :class="accountType == 5 ? 'on' : ''"
              @click="selectTab(5)"
            >
              刷卡登录
            </div>
            <div
              class="tab-item flex flex-center"
              :class="accountType == 1 ? 'on' : ''"
              @click="selectTab(1)"
            >
              账号登录
            </div>
          </div>
        </div>
        <div class="form-data flex-column flex-center">
          <div
            class="form-item flex-column flex-center"
            v-if="accountType == 5 && !isLogin"
          >
            <div class="item flex-column">
              <span>请刷员工卡</span>
              <input
                type="text"
                ref="cardInput"
                v-model="accountName"
                @keyup.enter="login"
                placeholder="请刷员工卡"
              />
            </div>
          </div>
          <div
            class="form-item flex-column flex-center"
            v-if="accountType == 1 && !isLogin"
          >
            <div class="item flex-column">
              <span>登录账号</span>
              <input
                type="text"
                ref="input"
                v-model="accountName"
                placeholder="请输入登录账号"
              />
            </div>
            <div class="item flex-column">
              <span>密码</span>
              <input
                type="passWord"
                v-model="password"
                placeholder="请输入密码"
              />
            </div>
          </div>
          <div class="form-item flex-column flex-center" v-if="isLogin">
            <div class="item flex-column flex-center">
              <span>选择开牌人数</span>
              <div class="number flex flex-center">
                <img @click="minusClick" src="@/assets/minus.png" alt="" />
                <p class="num">{{ peopleNumber }}</p>
                <img @click="addClick" src="@/assets/add.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-group flex-column flex-center">
          <blockquote v-if="!isLogin">
            <div class="login flex flex-center" @click="login">登 录</div>
            <div class="cancel flex flex-center" @click="cancelBox">
              取消登录
            </div>
          </blockquote>
          <blockquote v-if="isLogin">
            <div class="login flex flex-center" @click="openKeyCard">开 牌</div>
            <div class="cancel flex flex-center" @click="cancelBox">
              取消开牌
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    navBox,
  },
  name: "code",
  data() {
    return {
      sex: 1, // 1为男宾  2为女宾
      imageUrl: "", //小程序二维码
      timer: null,
      accountType: 5, // 账户类型（1:普通账号,2:手机号,3:邮箱,4: 微信OpenId,5:物理卡号,6: 苹果），默认1
      isLogin: false, // 是否登录
      peopleNumber: 1, // 开牌人数
      showLogin: false, // 显示登录弹窗
      accountName: "",
      password: "",
      loginToken: "",
    };
  },
  created() {
    this.sex = this.$route.query.sex;
    this.setTime();
    this.getCode();
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    this.timer = null;
  },
  methods: {
    login() {
      let data = {
        accountName: this.accountName,
        accountType: this.accountType,
        password: this.password,
        tenantIdOrStoreId: localStorage.getItem("storeId"),
      };
      if (this.accountType == 5) {
        if (this.accountName == "") {
          this.$toast("请刷员工卡");
          return false;
        }
      } else {
        if (this.accountName == "") {
          this.$toast("请输入账号");
          return false;
        }
        if (this.password == "") {
          this.$toast("请输入密码");
          return false;
        }
      }
      this.$post(this.Api.LoginApi, data).then((res) => {
        if (res.code == 1) {
          if (res.data.length > 0) {
            this.loginToken = res.data[0].token;
            this.isLogin = true;
          }
        }
      });
    },
    openKeyCard() {
      this.$toast.loading({
        message: "正在开牌中，请稍后....",
        forbidClick: true,
      });
      this.getKeyCode().then(async (res) => {
        this.$toast.clear();
        var that = this;
        console.log(res)
        if (res.data.length > 0) {
          that.$toast.loading({
            message: "正在打印中，请稍后....",
            forbidClick: true,
            duration: 3000,
          });
          if (typeof Android2JS != "undefined") {
            res.data.forEach((item) => {
              let storeName = localStorage.getItem("storeName");
              Android2JS.Print3Lines();
              Android2JS.PrintText(storeName, 48, true);
              Android2JS.PrintText("", true);
              Android2JS.PrintText("手牌号：" + item, 36, true);
              Android2JS.PrintText("打印时间：" + moment().format("YYYY/MM/DD HH:mm:ss"),32);
              Android2JS.PrintQrcode(item, 15);
              Android2JS.Print3Lines();
              Android2JS.CutPaper();
            });
          } else {
            // that.$toast("未检测到打印硬件，已跳过打印");

            let requestData = await this.requestUrlOrBody({type: "P10"});
            // 打印多张离场单
            res.data.forEach((item, index) => {
              setTimeout(() => {
                that.dataSet(requestData.body, [{KeyNo:item}]);
              }, 3000 * (index)); // 每隔1.5秒执行一次
            });

          }
        } else {
          that.$toast("暂无空闲手牌，请联系管理员...");
        }
      });
    },
    // 封装请求  返回报表请求地址和body
    // 传入获取报表类型如   {type: "P01"},
    async requestUrlOrBody(data) {
      var requestData = {
        requestUrl: "",
        body: "",
      };
      let printData = await this.$fetchNo(
        this.Api.PrintReport + "/" + data.type
      );
      requestData.body = printData.data.body;
      requestData.requestUrl = printData.data.dataInterfaceUrl;
      let reportData = await this.$fetchNo(this.Api.ReportFormat, {
        reportId: printData.data.id,
      });
      if (reportData.data.items.length > 0) {
        let list = reportData.data.items.filter((item) => item.isDefault);
        if (list.length > 0) {
          requestData.body = list[0].body;
        }
      }
      return requestData;
    },
    dataSet(body, data) {
      this.load = false;
      var that = this;
      var num = data.printCount?data.printCount:0;
      var invoiceAddressValue = data.invoiceAddress?data.invoiceAddress:'';
      //创建报表对象
      var report = new window.Stimulsoft.Report.StiReport();
      // 加载文件
      report.load(body);
      //新建数据源
      var dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      //数据源填充数据
      delete data.printCount
      delete data.invoiceAddress
      dataSet.readJson(JSON.stringify(data));
      //给报表的数据源填充数据
      
      let hotelInfo = localStorage.getItem("hotelInfo") || "{}";
      hotelInfo = JSON.parse(hotelInfo);
      let storeName = hotelInfo.storeName || "云水荟";
      report.regData(dataSet.dataSetName, storeName, dataSet);
      var variableString = report.dictionary.variables.getByName("StoreName");
      variableString.value = storeName;
      if (num) {
        var printCount = report.dictionary.variables.getByName("printCount");
        printCount.value = num;
      }
      if(invoiceAddressValue){
        var invoiceAddress = report.dictionary.variables.getByName("invoiceAddress");
        invoiceAddress.value = invoiceAddressValue;
      }
      
      // report.regBusinessObject("StoreName", "酒店名称");
      // 创建报表
      viewer.report = report;
      this.loading = true;
      setTimeout(() => {
        that.loading = false;
      }, 1200);
      report.renderAsync( async function () {
        that.loading = false;
        await report.print();
      });
    },
    async openCardSave() {
      var dataList = [];
      for (let i = 0; i < this.peopleNumber; i++) {
        try {
          let res = await this.getKeyCode();
          dataList.push(res);
        } catch (error) {
          console.log(error);
        }
      }
      return dataList;
    },
    getKeyCode() {
      var url =
        process.env.NODE_ENV == "development"
          ? "http://192.168.1.21:31000"
          : "https://api.spa.gshis.com";
      let data = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      }
      if(this.sex == 1){
        data.manQuantity = this.peopleNumber
      }else{
        data.femaleQuantity = this.peopleNumber
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            url + this.Api.ManualOpen,
            data,
            {
              headers: {
                access_token: this.loginToken,
                x_req_application_id: 1,
                x_req_terminal: 9,
                x_req_store_id: localStorage.getItem("storeId"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    // tab栏点击
    selectTab(index) {
      this.accountType = index;
      if (index == 5) {
        this.$nextTick(() => {
          this.$refs.cardInput.focus();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    // 减法点击
    minusClick() {
      if (this.peopleNumber > 1) {
        this.peopleNumber--;
      }
    },
    // 加法操作
    addClick() {
      this.peopleNumber++;
    },

    setTime() {
      //每隔一分钟运行一次保存方法
      this.timer = setInterval(() => {
        this.getCode();
      }, 60000);
    },
    getCode() {
      let data = {
        page: "pages/index/editCard/editCard",
        // page: "pages/index/index",
        expiry: 12,
        check_Path: true,
        isHyaline: true,
        width: 546,
        envVersion: "release", //envVersion:  要打开的小程序版本。正式版为 release，体验版为 trial，开发版为 develop，默认 release
        data: {
          sex: this.sex,
        },
      };
      this.$post(this.Api.qrcode, data, { responseType: "blob" }).then(
        (res) => {
          let blob = new Blob([res]);
          let url = window.URL.createObjectURL(blob);
          this.imageUrl = url;
        }
      );
    },
    openCard() {
      this.showLogin = true;
      this.$nextTick(() => {
        this.$refs.cardInput.focus();
      });
      // this.$toast("敬请期待...");
    },
    cancelBox() {
      this.accountType = 5; // 账户类型（1:普通账号,2:手机号,3:邮箱,4: 微信OpenId,5:物理卡号,6: 苹果），默认1
      this.isLogin = false; // 是否登录
      this.peopleNumber = 1; // 开牌人数
      this.showLogin = false; // 显示登录弹窗
      this.accountName = "";
      this.password = "";
      this.loginToken = "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container {
  position: relative;
  height: 100%;
}
.open {
  position: absolute;
  right: 0;
  bottom: 192px;
  width: 272px;
  height: 108px;
  border-radius: 100px 0px 0px 100px;
  background: linear-gradient(90deg, #007aff 0%, #5ac8fa 100%);
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}
.content {
  padding-top: 150px;
  .sex {
    margin-bottom: 100px;
    img {
      width: 200px;
      height: 200px;
    }
    span {
      font-size: 60px;
      color: #363d45;
      margin-left: 32px;
      font-weight: 500;
    }
  }
  .code-box {
    width: 850px;
    height: 850px;
    border-radius: 24px;
    background-color: #ecf5ff;
    .code {
      width: 546px;
      height: 546px;
      background-color: #fff;
      margin-bottom: 90px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tip {
      font-size: 40px;
      color: #363d45;
      font-weight: 500;
    }
  }
}
.pay-popup {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  .pay-window {
    width: 996px;
    height: 1120px;
    background-color: #fff;
    border-radius: 32px;
    box-sizing: border-box;
    .top-tab {
      width: 100%;
      box-sizing: border-box;
      padding-top: 64px;
      .tab {
        width: 686px;
        height: 140px;
        .tab-item {
          border: 4px solid #dadde1;
          flex: 1;
          font-size: 44px;
          color: #666666;
          font-weight: 500;
          &:first-child {
            border-right: 0;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }
          &:last-child {
            border-left: 0;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
          &.on {
            color: #fff;
            background-color: #007aff;
          }
        }
      }
    }
    .form-data {
      width: 100%;
      box-sizing: border-box;
      flex: 1;
      padding: 0 155px;
      .form-item {
        width: 100%;
        height: 100%;
        .item {
          width: 100%;
          margin-bottom: 56px;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            font-size: 44px;
            line-height: 64px;
            font-size: #1a1e22;
            margin-bottom: 32px;
          }
          input,
          select {
            width: 100%;
            height: 140px;
            background: #f4f4f4;
            box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            font-size: 44px;
            text-indent: 32px;
            color: #1a1e22;
            border: 0;
            &::placeholder {
              color: #b9bdc6;
            }
          }
          .number {
            margin-top: 30px;
            img {
              width: 88px;
              height: 88px;
            }
            .num {
              width: 142px;
              font-size: 66px;
              color: #333;
              font-weight: 700;
              text-align: center;
            }
          }
        }
      }
    }
    .btn-group {
      width: 100%;
      box-sizing: border-box;
      .login {
        width: 686px;
        height: 140px;
        background: #155bd4;
        border-radius: 16px;
        font-size: 40px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .cancel {
        width: 686px;
        height: 140px;
        font-size: 40px;
        font-weight: 700;
        color: #155bd4;
      }
    }
  }
}
</style>